(function ($) {
    $(document).ready(function () {
        const meetingList = $('#meetings-list-html');
        let formType;
        if (meetingList.length) {
            formType = meetingList.attr('data-fs');
        }

        const form = $('#eventRegistrationModal');
        form.validate({
            rules: {
                first_name: {
                    required: true
                },
                last_name: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                event_tos: {
                    required: formType === 'Event phone'
                }
            },
            messages: {
                first_name: 'First name is required',
                last_name: 'Last name is required',
                email: {
                    required: 'Email address is required'
                }
            }
        });

        $(document).on('click', '.ma-register-event', function () {
            const success = $('.event-submission-message');
            $('#event-registration-section').removeClass('d-none');
            const errors = $('.event-rsvp-errors');
            success.addClass('d-none');
            errors.addClass('d-none');
            const event = $(this).closest('.single-event');
            const modal = $('#registerModal');
            success.addClass('d-none');
            errors.addClass('d-none');
            modal.attr('data-id', event.attr('data-id'));
            modal.attr('data-rep', event.attr('data-rep'));
            modal.find('.event-name').html(event.find('.event-name').html());
            modal.find('.event-start-date').html(event.find('.event-start-date').html());
            modal.find('.event-end-date').html(event.find('.event-end-date').html());
            modal.find('.event-address').html(event.find('.event-address').html());
            modal.modal('show');
        });

        $(document).on('click', '.submit-rsvp', function () {
            if (form.valid()) {
                const success = $('.event-submission-message');
                const errors = $('.event-rsvp-errors');
                const loader = $('.custom-ajax-loader');
                success.addClass('d-none');
                errors.addClass('d-none');
                loader.show();
                $.ajax({
                    url: 'https://www.medicareanswers360.com/wp-json/hs360/v1/events',
                    contentType: "application/json",
                    dataType: 'json',
                    type: 'POST',
                    data: JSON.stringify({
                        eventId: form.closest('#registerModal').attr('data-id'),
                        repName: form.closest('#registerModal').attr('data-rep'),
                        first_name: form.find('#first_name').val(),
                        last_name: form.find('#last_name').val(),
                        email: form.find('#email').val(),
                        phone: form.find('#phone_number').val(),
                        market: 'New Jersey',
                        owner: '0054x0000056ZhaAAE',
                        tos: form.find('#event_tos').is(':checked') ? 'yes' : 'no',
                        form_source: $('#meetings-list-html').attr('data-fs')
                    }),
                    success: function (response) {
                        loader.hide();
                        if (response.success) {
                            $('#event-registration-section').addClass('d-none');
                            success.removeClass('d-none');
                            form.trigger('reset');
                        } else {
                            $('#event-registration-section').removeClass('d-none');
                            success.addClass('d-none');
                            errors.removeClass('d-none');
                        }
                    },
                    error: function () {
                        loader.hide();
                        errors.removeClass('d-none');
                    }
                });
            }
        });
    });
})(jQuery);